/* DesoPostApp.css */

@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&display=swap');

body {
  background-color: #121212;
  font-family: 'Rajdhani', sans-serif;
  margin: 0;
  padding: 0;
  color: #FFFFFF;
}

.app-container {
  display: flex;
  min-height: 100vh;
}

.nav-menu {
  background-color: #121212;
  width: 200px;
  padding: 16px;
  box-sizing: border-box;
}

.nav-button {
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  background-color: #121212;
  color: #FFFFFF;
  border: 1px solid #4E2F9A;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
}

.nav-button:hover {
  background-color: #1e1e1e;
}

.content {
  flex: 1;
  background-color: #000000;
  color: #FFFFFF;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.title-container {
  text-align: center;
  flex-grow: 1;
}

.title {
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  color: #FFFFFF;
}

.subtitle {
  font-size: 18px;
  font-weight: normal;
  margin: 8px 0 0;
  color: #AAAAAA;
}

.header-controls {
  display: flex;
  align-items: center;
}

.login-section {
  margin-bottom: 16px;
}

.post-area {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.text-input {
  width: 100%;
  min-height: 200px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: #1e1e1e;
  color: #FFFFFF;
  border: 1px solid #4E2F9A;
  border-radius: 4px;
  resize: vertical;
}

.post-controls-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.post-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.schedule-controls {
  display: flex;
  gap: 8px;
}

.emoji-button,
.icon-button,
.post-button,
.schedule-button {
  padding: 8px;
  background-color: #121212;
  color: #FFFFFF;
  border: 1px solid #4E2F9A;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.emoji-button:hover,
.icon-button:hover,
.post-button:hover,
.schedule-button:hover {
  background-color: #1e1e1e;
}

.post-button {
  font-size: 12px;
}

.date-input,
.time-input {
  padding: 8px;
  border: 1px solid #4E2F9A;
  border-radius: 4px;
  background-color: #1e1e1e;
  color: #FFFFFF;
}

.emoji-picker {
  position: absolute;
  z-index: 1000;
}

.post-status {
  margin-top: 8px;
  font-size: 14px;
  color: #FFFFFF;
}

.posts-container {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.saved-posts,
.scheduled-posts {
  flex: 1;
}

.saved-posts h2,
.scheduled-posts h2 {
  font-size: 24px;
  margin-bottom: 16px;
  color: #FFFFFF;
}

.post-item {
  margin-bottom: 16px;
  padding: 16px;
  background-color: #1e1e1e;
  border-radius: 4px;
}

.post-item p {
  margin: 8px 0;
  color: #FFFFFF;
}

.footer {
  margin-top: auto;
  padding-top: 16px;
  text-align: center;
  font-size: 14px;
  color: #888;
}

.light-mode .content,
.light-mode .nav-menu,
.light-mode .text-input,
.light-mode .post-item,
.light-mode .emoji-button,
.light-mode .icon-button,
.light-mode .post-button,
.light-mode .schedule-button {
  background-color: #FFFFFF;
  color: #000000;
  border-color: #4E2F9A;
}

.light-mode .date-input,
.light-mode .time-input {
  background-color: #FFFFFF;
  color: #000000;
  border-color: #4E2F9A;
}

.light-mode body {
  background-color: #FFFFFF;
  color: #000000;
}

.light-mode .footer {
  color: #555;
}

.light-mode .subtitle {
  color: #555555;
}

.light-mode .emoji-button:hover,
.light-mode .icon-button:hover,
.light-mode .post-button:hover,
.light-mode .schedule-button:hover {
  background-color: #F0F0F0;
}